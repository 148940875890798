import React from "react";
import {
  Grid,
} from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle";

import Iframe from 'react-iframe'

export default function Dashboard(props) {

  return (
    <>
      <PageTitle title="Dashboard" />
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
        <Iframe url="https://datastudio.google.com/embed/reporting/1FTErd1HvlbuFLKe6epVfcBBR6W6a5ZCb/page/Dbk5"
        position="absolute"
            width="50%"
            height="50%"
            frameBorder="0"
            allowFullScreen
            styles={{height: "25px"}}/>
        </Grid>
      </Grid>
    </>
  );
}