import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';

// styles
import useStyles from "./styles";

// logo
import logo from "./airasia.png"
import { APIBASEURL } from '../../context/Constants'

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);

  const googleResponseError = (response) => {
    alert(response);
  }

  const googleResponse = (response) => {
        const options = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              access_token: response.accessToken
            })
        };
        
        fetch(APIBASEURL + 'social/google-oauth2/', options).then(r => {
          if (!r.ok) {
              throw Error(r.statusText);
          }
          return r.json();
        }).then(function(data) {
          const token = data.token;
          
          if (token) {
            loginUser(userDispatch, token, data.name, data.username, props.history, setIsLoading, setError);
          }
        }).catch(function(error) {
            alert('Problem logging in via Google');
        });
    };

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Cloud Asset Management Portal</Typography>


        <div className={classes.form}>
            <React.Fragment>
              <GoogleLogin
                clientId="6774754529-s6mfnodq82e23fiqn1qkh37cn4qne1b8.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={googleResponse}
                onFailure={googleResponseError}
                cookiePolicy={'single_host_origin'}
              />
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  null
                )}
              </div>
            </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2019 AirAsia Berhad
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);