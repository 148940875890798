import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { APIBASEURL } from '../../../context/Constants'

class ServiceAccounts extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            count: 1,
            isLoading: false,
            data: [["", "Loading Data...", ""]],

        };
    }

    componentDidMount() 
    { 
        this.getData(); 
    }

    getData = () => {
        this.setState({ isLoading: true });
        this.xhrRequest().then(res => {
            this.setState({ data: res.data, isLoading: false, count: res.total });
        })
    }

    xhrRequest = () => {

        return new Promise((resolve, reject) => {
            
            const url = APIBASEURL + 'gcp/service-accounts/?format=json'
            
            let xhr = new XMLHttpRequest();
            xhr.addEventListener('load', () => {
                const res = JSON.parse(xhr.responseText);
                const data = res.results;
                const total = res.count;
                setTimeout(() => {
                    resolve({
                        data, total
                    });
                }, 2500);
            });

            xhr.open('GET', url, true);
            xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
            xhr.send();
        
        });
    }

    render() 
    {
        const columns = [
            { 
                name: "name",
                label: "SERVICE ACCOUNT",
                options: {
                    filter: true, 
                    sort: true,
                }
            },
            {
                name: "data",
                label: "ROLES",
                options: {
                  filter: false,
                  sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value && value !== "Loading Data...")
                        {
                            const parsed_list = [];
                            value.forEach((item, index) => {
                                let json_data = JSON.parse(item);
                                parsed_list.push("Project: " + json_data.project_name + " (" + json_data.project_number + "): " + json_data.role);
                            });
                            return (
                                <ul>
                                    {parsed_list.map(item => <li key={item + "_" + Math.random().toString(10)}>{item}</li>)}
                                </ul>
                            )

                        }
                        return value;
                    }
                }
                
            },
            {
                name: "count",
                label: "ROLE COUNT",
                options: {
                    filter: false, 
                    sort: true
                }
            }
        ];
        const options = {
            filter: false,
            filterType: 'dropdown',
            sort: true,
            selectableRows: 'none',
            searchPlaceholder: 'Search Service Accounts',
            responsive: 'stacked',
            count: this.state.count,
            print: false,
            rowsPerPage: 50,
            rowsPerPageOptions: [50, 100, 150, 200],
        }

        return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
              <MUIDataTable title={<Typography variant="h3">
                Service Accounts
                {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                </Typography>
                } data={this.state.data} columns={columns} options={options} />
              </Grid>
            </Grid>
          );
    }
}

export default ServiceAccounts;
