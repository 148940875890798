
import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { APIBASEURL } from '../../../context/Constants'


class ComputeInstances extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      data: [["", "", "Loading Data..."]],
      isLoading: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });

    this.xhrRequest().then(res => {
      this.setState({ data: res.data, isLoading: false, count: res.total });
    });
  }

  // mock async function
  xhrRequest = () => {
    return new Promise((resolve, reject) => {

      var url = APIBASEURL + 'gcp/compute_instances/?format=json'

      var xhr = new XMLHttpRequest()

      xhr.addEventListener('load', () => {
        var res = JSON.parse(xhr.responseText);
        var data = res.results;
        var total = res.count;

        setTimeout(() => {
          resolve({
            data, total
          });
        }, 2500);

      })
      xhr.open('GET', url, true)
      xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
      xhr.send()
    });
  }

  render() {
    const columns = [
      {
        name: "properties.name",
        label: "NAME",
        options: {
          filter: false
        }
      },
      {
        name: "project_id",
        label: "PROJECT",
        options: {
          filter: true,
        }
      },      
      {        
        name: "properties.status",
        label: "STATUS",
        options: {
          filter: true
        }
      },
      {        
        name: "properties.machineType",
        label: "TYPE",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value) {
              return value.split("/")[10];
            }
            return value;
          }
        }
      },
      {        
        name: "properties.os",
        label: "OS",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value) {
              var splitVal = value.split("-");
              if (['windows', 'centos', 'debian', 'rhel', 'ubuntu'].indexOf(splitVal[0]) >= 0) {
                return splitVal.join(" ").toUpperCase();
              }
              else {
                return "-";
              }

            }
            return value;
          }
        }
      },
      {        
        name: "properties.machineType",
        label: "REGION",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value !== undefined) {
              var result = value.split("/")[8].split("-");
              result.pop(2);
              return result.join("-");
            }
            return value;
          }
        }
      },
      {        
        name: "properties.cpuPlatform",
        label: "CPU PLATFORM",
        options: {
          filter: false,
          display: false,
        }
      },
      {
        name: "owners",
        label: "OWNERS",
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value) {
              return (
                <ul>
                  {value.map((owner) => <li key={owner}>{owner.split(":")[1]}</li>)}
                </ul>
              );
            }
            return value;
          }
        }
      },
      {
        name: "properties.privateIp",
        label: "PRIVATE IP",
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: "properties.publicIp",
        label: "PUBLIC IP",
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: "create_time",
        label: "CREATED",
        options: {
          filter: false
        }
      },
      {
        name: "update_time",
        label: "UPDATED",
        options: {
          filter: false,
          display: false
        }
      },
      {        
        name: "properties.currentMonthCost",
        label: "COST (ESTIMATE)",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(!value) {
              return "-";
            }
            return "$" + parseFloat(value).toFixed(2);
          }
        }
      }
    ];
    const { data, count, isLoading } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      sort: true,
      selectableRows: 'none',
      searchPlaceholder: 'Search Compute Instance',
      responsive: 'stacked',
      count: count,
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [50, 100, 150, 200],
    };
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <MUIDataTable title={<Typography variant="h3">
          GCP | Compute Instances
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
    );

  }
}

export default ComputeInstances;