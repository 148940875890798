import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

import Projects from "../../pages/gcp/projects";
import IAMPolicy from "../../pages/gcp/iam-policy";
import Applications from "../../pages/gcp/applications";
import SQLInstances from "../../pages/gcp/sql-instances";
import ComputeInstances from "../../pages/gcp/compute-instances";
import SummaryAssets from "../../pages/gcp/summary-assets";
import StorageBuckets from "../../pages/gcp/storage-buckets";
import ContainerClusters from "../../pages/gcp/container-clusters";

import BigqueryDatasets from "../../pages/gcp/bigquery-datasets";
import BigqueryTableList from '../../pages/gcp/bigquery-datasets/table-list';

import SecurityFindings from "../../pages/gcp/security-findings"
import SecurityDashboard from "../../pages/gcp/security-dashboard";
import ComputeAddresses from "../../pages/gcp/compute-addresses";
import ServiceAccounts from "../../pages/gcp/service-accounts";
 
import EC2Instances from "../../pages/aws/ec2-instances";
import RDSInstances from "../../pages/aws/rds-instances";
import S3Buckets from "../../pages/aws/s3-buckets";
import EBApplications from "../../pages/aws/eb-applications";

import AlibabaECSInstances from "../../pages/alibaba/ecs-instances";
import AlibabaRDSInstances from "../../pages/alibaba/rds-instances";
import AlibabaCDNDomains from "../../pages/alibaba/cdn-domains";

import AzureComputeInstances from "../../pages/azure/compute-instances";
import AzureSQLServers from "../../pages/azure/sql-servers";
import AzureStorageContainers from "../../pages/azure/storage-containers"

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route exact path="/app/security/dashboard" component={SecurityDashboard} />
              <Route exact path="/app/gcp/projects" component={Projects} />
              <Route exact path="/app/gcp/projects/:projectNumber/iampolicy" component={IAMPolicy} />
              <Route exact path="/app/gcp/projects/:projectNumber/iampolicy/:filter" component={IAMPolicy} />
              <Route exact path="/app/gcp/projects/:projectId/assets" component={SummaryAssets} />
              <Route exact path="/app/gcp/applications" component={Applications} />
              <Route exact path="/app/gcp/compute-instances" component={ComputeInstances} />
              <Route exact path="/app/gcp/compute-addresses" component={ComputeAddresses} />
              <Route exact path="/app/gcp/sql-instances" component={SQLInstances} />
              <Route exact path="/app/gcp/storage-buckets" component={StorageBuckets} />
              <Route exact path="/app/gcp/container-clusters" component={ContainerClusters} />
              
              <Route exact path="/app/gcp/bigquery-datasets" component={BigqueryDatasets} />
              <Route exact path="/app/gcp/bigquery-datasets/:projectId/:datasetId/" component={BigqueryTableList} />

              <Route exact path="/app/gcp/security-findings/:source_id" component={SecurityFindings} />
              <Route exact path="/app/gcp/service-accounts" component={ServiceAccounts} />

              <Route exact path="/app/aws/ec2-instances" component={EC2Instances} />
              <Route exact path="/app/aws/rds-instances" component={RDSInstances} />
              <Route exact path="/app/aws/s3-buckets" component={S3Buckets} />
              <Route exact path="/app/aws/eb-applications" component={EBApplications} />

              <Route exact path="/app/alibaba/ecs-instances" component={AlibabaECSInstances} />
              <Route exact path="/app/alibaba/rds-instances" component={AlibabaRDSInstances} />
              <Route exact path="/app/alibaba/cdn-domains" component={AlibabaCDNDomains} />

              <Route exact path="/app/azure/compute-instances" component={AzureComputeInstances} />
              <Route exact path="/app/azure/sql-servers" component={AzureSQLServers} />
              <Route exact path="/app/azure/storage-containers" component={AzureStorageContainers} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
