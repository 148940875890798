import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// Components
import { Button } from "../../../components/Wrappers";
import { Link } from 'react-router-dom';
import { APIBASEURL } from '../../../context/Constants'


class BigqueryDatasets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      data: [["", "", "Loading Data..."]],
      isLoading: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });

    this.xhrRequest().then(res => {
      this.setState({ data: res.data, isLoading: false, count: res.total });
    });
  }

  // mock async function
  xhrRequest = () => {
    return new Promise((resolve, reject) => {

      var url = APIBASEURL + 'gcp/bigquery_datasets/?format=json'
      
      var xhr = new XMLHttpRequest()

      xhr.addEventListener('load', () => {
        var res = JSON.parse(xhr.responseText);
        var data = res.results;
        var total = res.count;

        setTimeout(() => {
          resolve({
            data, total
          });
        }, 2500);

      })
      xhr.open('GET', url, true)
      xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
      xhr.send()
    });
  }


  render() {
    const columns = [
      {
        name: "properties.id",
        label: "NAME",
        options: {
          filter: false
        }
      },
      {
        name: "project_id",
        label: "PROJECT",
        options: {
          filter: true
        }
      },
      {
        name: "project_number", 
        label: "IAM POLICY",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (this.state.isLoading && value)
            {
              return value;
            }
            else if (this.state.isLoading)
            {
              return "";
            }
            else if (value)
            {
              return (<Button
                variant="contained"
                color="primary"
                component = {Link}  
                to={{ pathname: '/app/gcp/projects/'+ value + '/iampolicy/bigquery' }}
                size="small"
                >
                  SHOW BQ IAM POLICY
              </Button>);
            } else return "";
          }
        }
      },
      {
        name: "properties.location",
        label: "LOCATION",
        options: {
          filter: true,
        }
      },
      {
        name: "owners",
        label: "OWNERS",
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value) {
              return (
                <ul>
                  {value.map((owner) => <li key={owner}>{owner.split(":")[1]}</li>)}
                </ul>
              );
            }
            return value;
          }
        }
      },
      {
        name: "create_time",
        label: "CREATED",
        options: {
          filter: false
        }
      },
      {
        name: "update_time",
        label: "UPDATED",
        options: {
          filter: false,
          display: false
        }
      },
      {
        name: "table_count_data", 
        label: "TABLE DETAILS",
        options: {
          filter: false,
          // sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {            
            if (this.state.isLoading)
            {
              return "";
            }
            else if (value)
            {             
              const components = value.split(':');
              const project_dataset_id = components[0];
              const dataset_count = parseInt(components[1]);
          
              if (dataset_count > 0)
              {
                return (<Button
                          variant="contained"
                          color="primary"
                          component = {Link}  
                          to={{ pathname: '/app/gcp/bigquery-datasets/' + project_dataset_id + "/" }}
                          size="small"
                          >
                            TABLE DETAILS
                        </Button>);
              }
            }
            return "N/A";
            
          }
        }
      },
    ];
    const { data, count, isLoading } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      sort: true,
      selectableRows: 'none',
      searchPlaceholder: 'Search Datasets',
      responsive: 'stacked',
      count: count,
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [50, 100, 150, 200],
    };
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <MUIDataTable title={<Typography variant="h3">
          GCP | BigQuery Datasets
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
    );

  }
}

export default BigqueryDatasets;