import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import { Button } from "../../../components/Wrappers";
import { APIBASEURL } from '../../../context/Constants'

class SecurityFindings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      count: 1,
      serverSideFilterList: [],
      filters: [[], [], []],
      data: [["", "", "Loading Data..."]],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  // get data
  getData = () => {
    this.setState({ isLoading: true });

    this.xhrRequest(this.state.page).then(res => {
      this.setState({ data: res.data, isLoading: false, count: res.total });
    });
  }

  // mock async function
  xhrRequest = (page) => {
    page++;
    return new Promise((resolve, reject) => {

      var url = APIBASEURL + 'gcp/security/findings/' + this.props.match.params.source_id + '/?page=' + page + '&format=json'


      var xhr = new XMLHttpRequest()

      xhr.addEventListener('load', () => {
        var res = JSON.parse(xhr.responseText);
        var data = res.results;
        var total = res.count;

        setTimeout(() => {
          resolve({
            data, total
          });
        }, 2500);

      })
      xhr.open('GET', url, true)
      xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
      xhr.send()
    });

  }

  changePage = (page) => {
    this.setState({
      isLoading: true,
      data: [["", "", "Loading Data..."]],
    });
    this.xhrRequest(page).then(res => {
      this.setState({
        isLoading: false,
        page: page,
        data: res.data,
        count: res.total
      });
    });
  };

  handleFilterSubmit = filterList => () => {
    console.log('Submitting filters: ', filterList);

    this.setState({ isLoading: true, filters: filterList });

    // fake async request
    this.xhrRequest(`/myApiServer?filters=${filterList}`, filterList).then(res => {
      this.setState({ isLoading: false, data: res.data, serverSideFilterList: filterList });
    });
  };

  render() {
    const columns = [
      {
        name: "category",
        label: "CATEGORY",
      },
      {
        name: "source",
        label: "SOURCE",
        options: {
          filter:false,
        }
      },
      {
        name: "project",
        label: "PROJECT",
      },
      {
        name: "severity",
        label: "SEVERITY",
      },
      {
        name: "create_time",
        label: "CREATE TIME",
        options: {
          filter:false,
        }
      },
      {
        name: "event_time",
        label: "EVENT TIME",
        options: {
          filter:false,
        }
      }
    ];
    const { data, page, count, isLoading } = this.state;

    const options = {
      filter: true,
      sort: true,
      filterType: 'dropdown',
      selectableRows: 'none',
      search: false,
      searchPlaceholder: 'Search Finding',
      responsive: 'stacked',
      serverSide: true,
      count: count,
      page: page,
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [50,],
      onTableChange: (action, tableState) => {
        if (action === 'changePage') {
          this.changePage(tableState.page);
        }
      },
      onFilterChange: (column, filterList, type) => {
        if (type === 'chip') {
          console.log('updating filters via chip');
          this.handleFilterSubmit(filterList)();
        }
      },
      customFilterDialogFooter: filterList => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant="contained"
                        color="primary" onClick={this.handleFilterSubmit(filterList)}>Apply Filters</Button>
          </div>
        );
      }
    };
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <MUIDataTable title={<Typography variant="h3">
          GCP | Security Findings
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
    );

  }
}

export default SecurityFindings;