import React from 'react'
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { APIBASEURL } from '../../../../context/Constants'

class BigqueryTableList extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            isLoading: false,
            data: [["","", "Loading Data..."]],
            count: 1,
            projectId: this.props.match.params.projectId,
            datasetId: this.props.match.params.datasetId
        };
    }

    componentDidMount() { this.getData(); }

    getData = () => {
        this.setState({ isLoading: true });

        this.xhrRequest().then(res => {
            this.setState({ data: res.data, isLoading: false, count: res.total });
        });
    }

    xhrRequest = () => {
        return new Promise((resolve, reject) => {
        
            const url = APIBASEURL + 'gcp/bigquery/projects/' + this.state.projectId + '/datasets/' + this.state.datasetId + '/?format=json';
            
            let xhr = new XMLHttpRequest();

            xhr.addEventListener('load', () => {
                var res = JSON.parse(xhr.responseText);
                var data = res.results;
                var total = res.count;
                
                setTimeout(() => {
                    resolve({
                      data, total
                    });
                  }, 2500);
                
            });

            xhr.open('GET', url, true);
            xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
            xhr.send();
        });
    };


    render() 
    {
        const columns = [
            {
                name: "table_id",
                label: "TABLE ID"
            },
            {
                name: "location",
                label: "LOCATION",
            },
            {
                name: "size_mb",
                label: "TABLE SIZE (MB)",
                options: 
                {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
    
                        if (this.state.isLoading)
                        {
                            return value;
                        }
                        else if (value !== undefined)
                        {
                            return parseFloat(value).toFixed(3);
                        }
                        return "";
                    }
                },
            },
            {
                name: "table_type",
                label: "TYPE"
            },
            {
                name: "row_count",
                label: "ROW COUNT",
                options: 
                {
                    filter: false
                }
            },
            {
                name: "creation_time",
                label: "CREATION TIME",
                options: 
                {
                    filter: false, 
                    sort: false
                }
            },
            {
                name: "last_modified_time",
                label: "LAST MODIFIED TIME",
                options: 
                {
                    filter: false, 
                    sort: false
                }
            },
        ];
        const options = {
            filter: true,
            filterType: 'dropdown',
            sort: true,
            selectableRows: 'none',
            searchPlaceholder: 'Search Datasets',
            responsive: 'stacked',
            count: this.state.count,
            print: false,
            rowsPerPage: 50,
            rowsPerPageOptions: [50, 100, 150, 200],
        };
        return (
    
        
        <Grid container spacing={4}>
            <Grid item xs={12}>
            <MUIDataTable title={<Typography variant="h3">
            GCP BQ Dataset - Project: {this.state.projectId}, Dataset: {this.state.datasetId}
            {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
            </Typography>
            } data={this.state.data} columns={columns} options={options} />
            </Grid>
        </Grid>
     
        );

    }
}

export default BigqueryTableList;