
import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { APIBASEURL } from '../../../context/Constants'


class EBApplications extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      data: [["", "", "Loading Data..."]],
      isLoading: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });

    this.xhrRequest().then(res => {
      this.setState({ data: res.data, isLoading: false, count: res.total });
    });
  }

  // mock async function
  xhrRequest = () => {
    return new Promise((resolve, reject) => {

      var url = APIBASEURL + 'aws/eb_applications/?format=json'

      var xhr = new XMLHttpRequest()

      xhr.addEventListener('load', () => {
        var res = JSON.parse(xhr.responseText);
        var data = res.results;
        var total = res.count;

        setTimeout(() => {
          resolve({
            data, total
          });
        }, 2500);

      })
      xhr.open('GET', url, true)
      xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
      xhr.send()
    });
  }

  render() {
    const columns = [
      {
        name: "meta.ApplicationName",
        label: "APP NAME",
        options: {
          filter: false
        }
      },
      {
        name: "meta.Description",
        label: "DESCRIPTION",
        options: {
          filter: false
        }
      },
      {
        name: "meta.Environments",
        label: "OS",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value[0]){
              function get(object, key, default_value) {
                  var result = object[key];
                  return (typeof result !== "undefined") ? result : default_value;
              }
              console.log(value);
              return get(value[0], "SolutionStackName", "").split("running")[0];
            }
          }
        }
      },
      {
        name: "account",
        label: "AWS ACCOUNT",
        options: {
          filter: true,
          display: false,
        }
      },
      {
        name: "region",
        label: "REGION",
        options: {
          filter: true,
        }
      },
      {
        name: "meta.DateCreated",
        label: "CREATED",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value){
              return value.split("T")[0];
            }
          }
        }
      },
      {
        name: "meta.DateUpdated",
        label: "UPDATED",
        options: {
          filter: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value){
              return value.split("T")[0];
            }
          }
        }
      }
    ];
    const { data, count, isLoading } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      sort: true,
      selectableRows: 'none',
      searchPlaceholder: 'Search EB Application',
      responsive: 'stacked',
      count: count,
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [50, 100, 150, 200],
    };
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <MUIDataTable title={<Typography variant="h3">
          AWS | EB Application
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
    );

  }
}

export default EBApplications;