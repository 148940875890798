import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";
// icons sets
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/brands.css";
import "@fortawesome/fontawesome-free/css/solid.css";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <i className="fas fa-home fa-lg" /> },
  { id: 1, type: "divider" },
  { id: 2, type: "title", label: "GCP" },
  { id: 3, label: "Projects", link: "/app/gcp/projects", icon: <i className="fas fa-layer-group fa-lg" /> },
  { id: 4, label: "Applications", link: "/app/gcp/applications", icon: <i className="fas fa-project-diagram fa-lg" /> },
  { id: 5, label: "Compute Instances", link: "/app/gcp/compute-instances", icon: <i className="fa fa-microchip fa-lg" /> },
  { id: 6, label: "Compute Addresses", link: "/app/gcp/compute-addresses", icon: <i className="fa fa-link fa-lg" /> },
  { id: 7, label: "SQL Instances", link: "/app/gcp/sql-instances", icon: <i className="fa fa-database fa-lg" /> },
  { id: 8, label: "Storage Buckets", link: "/app/gcp/storage-buckets", icon: <i className="fa fa-server fa-lg" /> },
  { id: 9, label: "Clusters", link: "/app/gcp/container-clusters", icon: <i className="fas fa-cubes fa-lg" /> },
  { id: 10, label: "BigQuery Datasets", link: "/app/gcp/bigquery-datasets", icon: <i className="fas fa-coins fa-lg" /> },
  { id: 11, label: "Service Accounts", link: "/app/gcp/service-accounts", icon: <i className="fas fa-user-cog fa-lg" />},
  { id: 12, type: "title", label: "AWS" },
  { id: 13, label: "EC2", link: "/app/aws/ec2-instances", icon: <i className="fa fa-microchip fa-lg" /> },
  { id: 14, label: "RDS", link: "/app/aws/rds-instances", icon: <i className="fa fa-database fa-lg" /> },
  { id: 15, label: "S3", link: "/app/aws/s3-buckets", icon: <i className="fa fa-server fa-lg" /> },
  { id: 16, label: "Elastic Beanstalk", link: "/app/aws/eb-applications", icon: <i className="fas fa-project-diagram fa-lg" /> },
  { id: 17, type: "title", label: "ALIBABA" },
  { id: 18, label: "ECS", link: "/app/alibaba/ecs-instances", icon: <i className="fa fa-microchip fa-lg" /> },
  { id: 19, label: "RDS", link: "/app/alibaba/rds-instances", icon: <i className="fa fa-database fa-lg" /> },
  { id: 20, label: "CDN", link: "/app/alibaba/cdn-domains", icon: <i className="fab fa-keycdn fa-lg" /> },
  { id: 21, type: "title", label: "AZURE" },
  { id: 22, label: "Compute", link: "/app/azure/compute-instances", icon: <i className="fa fa-microchip fa-lg" /> },
  { id: 23, label: "SQL", link: "/app/azure/sql-servers", icon: <i className="fa fa-database fa-lg" /> },
  { id: 24, label: "Storage", link: "/app/azure/storage-containers", icon: <i className="fa fa-server fa-lg" /> },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
