
import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { APIBASEURL } from '../../../context/Constants'


class SummaryAssets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      data: [["", "", "Loading Data..."]],
      isLoading: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });

    this.xhrRequest().then(res => {
      this.setState({ data: res.data, isLoading: false, count: res.total });
    });
  }

  // mock async function
  xhrRequest = () => {
    return new Promise((resolve, reject) => {
      var projectId = this.props.match.params.projectId;

      var url = APIBASEURL + 'gcp/projects/' + projectId + '/assets/?format=json'

      var xhr = new XMLHttpRequest()

      xhr.addEventListener('load', () => {
        var res = JSON.parse(xhr.responseText);
        var data = res.results;
        var total = res.count;

        setTimeout(() => {
          resolve({
            data, total
          });
        }, 2500);

      })
      xhr.open('GET', url, true)
      xhr.setRequestHeader('Authorization', 'Token ' + localStorage.token);
      xhr.send()
    });
  }

  render() {
    const columns = [
      {
        name: "properties.name",
        label: "NAME",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "properties.type",
        label: "TYPE",
        options: {
          filter: true,
          sort: true,
        }
      },  
      {        
        name: "properties.status",
        label: "STATUS",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "properties.privateIp",
        label: "PRIVATE IP",
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: "properties.publicIp",
        label: "PUBLIC IP",
        options: {
          filter: false,
          sort: false,
        }
      }
    ];
    const { data, count, isLoading } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      sort: true,
      selectableRows: 'none',
      searchPlaceholder: 'Search Assets',
      responsive: 'stacked',
      count: count,
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [50, 100, 150, 200],
    };
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <MUIDataTable title={<Typography variant="h3">
          GCP | Assets of {this.props.match.params.projectId}
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
    );

  }
}

export default SummaryAssets;